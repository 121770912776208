.app {
  text-align: left;
  background-color: #f3f2f2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #0e0620;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: calc(10px + 4vmin);
  background-color: #2c2c2c;
  position: fixed;
}

.unsubscribed-footnote {
  font-size: x-small;
  padding-left: 14px;
  color: rgba(43, 151, 33, 0.6);
}

.error-code {
  font-size: calc(10px + 7vmin);
  padding-top: 0.5em;
  padding-bottom: 0.6em;
  font-weight: bold;
}

.error-type {
  font-size: calc(2px + 2vmin);
  padding-bottom: 1.5em;
  font-weight: bold;
}

.error-message {
  font-size: calc(5px + 1.2vmin);
}
